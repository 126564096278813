// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAa5s5N-xlwuR2e-eXlrCUzOk2xvaOJIR0",
  authDomain: "crystal-clear-cleaners-4238c.firebaseapp.com",
  projectId: "crystal-clear-cleaners-4238c",
  storageBucket: "crystal-clear-cleaners-4238c.appspot.com",
  messagingSenderId: "623682393771",
  appId: "1:623682393771:web:d4f382e2fd067d1fcd49ee",
  measurementId: "G-BE5NGZ83P8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
