import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from "react-responsive-carousel";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCity, faHotel, faSpa } from "@fortawesome/free-solid-svg-icons";
import ServiceCard from "./service-card";

import "./style.css";

const Services = () => {
  const services = [
    {
      type: "Offices and Buildings",
      points: [
        "Entrances, lobbies & common areas",
        "Common areas, washrooms & elevators",
        "Windows & Desk cleaning",
        "Carpeting and upholstery cleaning and maintenance",
      ],
    },
    {
      type: "Banks / Financial",
      points: [
        "Entrances, lobbies & common areas",
        "Common areas, washrooms & elevators",
        "Windows & Desk cleaning",
        "Carpeting and upholstery cleaning and maintenance",
      ],
    },
    {
      type: "Education / Schools",
      points: [
        "Comprehensive cleaning",
        "Flexible schedules",
        "Professional staff",
      ],
    },
    {
      type: "Gym and Fitness",
      points: ["Deep cleaning", "Stain removal", "Eco-friendly products"],
    },
    {
      type: "Industrial / Manufacturing",
      points: ["Deep cleaning", "Stain removal", "Eco-friendly products"],
    },
    {
      type: "Retail",
      points: ["Deep cleaning", "Stain removal", "Eco-friendly products"],
    },
    {
      type: "Car Dealership",
      points: ["Deep cleaning", "Stain removal", "Eco-friendly products"],
    },
    {
      type: "Medical Facilities",
      points: ["Deep cleaning", "Stain removal", "Eco-friendly products"],
    },
    // Add more services as needed
  ];

  return (
    // <div className="container-fluid bg-service py-5">
    //   <div className="container py-5">
    //     <div className="row align-items-center">
    //       <div className="col-lg-6">
    //         <h6 className="text-secondary font-weight-semi-bold text-uppercase mb-3">
    //           Our Services
    //         </h6>
    //         <h1 className="mb-4 section-title text-white">
    //           Awesome Cleaning Services For You
    //         </h1>
    //         <p className="text-white">
    //           Invidunt lorem justo clita. Erat lorem labore ea, justo dolor
    //           lorem ipsum ut sed eos, ipsum et dolor kasd sit ea justo. Erat
    //           justo sed sed diam. Ea et erat ut sed diam sea ipsum
    //         </p>
    //         <a href="" className="btn btn-primary mt-3 py-2 px-4">
    //           More Services
    //         </a>
    //       </div>
    //       <div className="col-lg-6 pt-5 pt-lg-0">
    //         <div className="owl-carousel service-carousel position-relative">
    //           <Carousel>
    //             <div className="d-flex flex-column align-items-center text-center bg-white rounded overflow-hidden pt-4">
    //               <div className="icon-box bg-light text-secondary shadow mt-2 mb-4">
    //                 <i className="fa fa-2x fa-hotel"></i>
    //                 <FontAwesomeIcon
    //                   icon={faHotel}
    //                   className="fa-3x text-secondary"
    //                 />
    //               </div>
    //               <h5 className="font-weight-bold mb-4 px-4">Home Cleaning</h5>
    //               <img src="img/blog-1.jpg" alt="Home Cleaning" />
    //             </div>
    //             <div className="d-flex flex-column align-items-center text-center bg-white rounded overflow-hidden pt-4">
    //               <div className="icon-box bg-light text-secondary shadow mt-2 mb-4">
    //                 <FontAwesomeIcon
    //                   icon={faCity}
    //                   className="fa-3x text-secondary"
    //                 />
    //               </div>
    //               <h5 className="font-weight-bold mb-4 px-4">
    //                 Window Cleaning
    //               </h5>
    //               <img src="img/blog-3.jpg" alt="Window Cleaning" />
    //             </div>
    //             <div className="d-flex flex-column align-items-center text-center bg-white rounded overflow-hidden pt-4">
    //               <div className="icon-box bg-light text-secondary shadow mt-2 mb-4">
    //                 <FontAwesomeIcon
    //                   icon={faSpa}
    //                   className="fa-3x text-secondary"
    //                 />
    //               </div>
    //               <h5 className="font-weight-bold mb-4 px-4">
    //                 Carpet Cleaning
    //               </h5>
    //               <img src="/img/blog-2.jpg" alt="Carpet Cleaning" />
    //             </div>
    //           </Carousel>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div className="container">
      <div className="row">
        <div className="col-auto">
          {/* <div className="pricing-card text-center">
            <h2>Basic Plan</h2>
            <p>$10/month</p>
            <ul>
              <li>Feature 1</li>
              <li>Feature 2</li>
              <li>Feature 3</li>
            </ul>
            <button className="btn btn-primary">Select Plan</button>
          </div> */}

          <div className="services-container">
            {services.map((service, index) => (
              <ServiceCard key={index} service={service} />
            ))}
          </div>
        </div>

        {/* <div className="col-lg-4 col-md-6">
          <div className="pricing-card text-center">
            <h2>Standard Plan</h2>
            <p>$20/month</p>
            <ul>
              <li>Feature 1</li>
              <li>Feature 2</li>
              <li>Feature 3</li>
              <li>Feature 4</li>
            </ul>
            <button className="btn btn-primary">Select Plan</button>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
          <div className="pricing-card text-center">
            <h2>Premium Plan</h2>
            <p>$30/month</p>
            <ul>
              <li>Feature 1</li>
              <li>Feature 2</li>
              <li>Feature 3</li>
              <li>Feature 4</li>
              <li>Feature 5</li>
            </ul>
            <button className="btn btn-primary">Select Plan</button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Services;
