import Carouselindex from "../components/carousel-index"
import Contactinfo from "../components/contact-info"
import Testimonials from "../components/testimonial-index"

const Home = () => {
  return (
    <>
      <Carouselindex />
      <Contactinfo />
      <Testimonials />
    </>
  )
}

export default Home