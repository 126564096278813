import React from "react"

const Footer = () => {
  return (
    <>
      <div className="container-fluid bg-dark text-white mt-5 py-5 px-sm-3 px-md-5">
        <div className="row pt-5">
            <div className="col-lg-5 col-md-4 mb-5">
                <a href="index.html" className="navbar-brand">
                    {/* <h6 className="m-0 mt-n3 display-6 text-primary">Crystal Clear Cleaners</h6> */}
                    <h4 className="m-0 mt-n3 font-weight-semi-bold text-primary mb-4">Crystal Clear Cleaners</h4>
                </a>
                <p>Reliable and Trustable Cleaning Agency in GTA</p>
                <h5 className="font-weight-semi-bold text-white mb-2">Opening Hours:</h5>
                <p className="mb-1">Mon – Sat, 8AM – 5PM</p>
                <p className="mb-0">Sunday: Closed</p>
            </div>
            <div className="col-lg-5 col-md-4 mb-5">
                <h4 className="font-weight-semi-bold text-primary mb-4">Get In Touch</h4>
                <p><i className="fa fa-map-marker-alt text-primary mr-2"></i>Brampton, ON, CA</p>
                <p><i className="fa fa-phone-alt text-primary mr-2"></i>+16475072809</p>
                <p><i className="fa fa-envelope text-primary mr-2"></i>crystalclearcleaners09@gmail.com</p>
                {/* <div className="d-flex justify-content-start mt-4">
                    <a className="btn btn-light btn-social mr-2" href="#"><i className="fab fa-twitter"></i></a>
                    <a className="btn btn-light btn-social mr-2" href="#"><i className="fab fa-facebook-f"></i></a>
                    <a className="btn btn-light btn-social mr-2" href="#"><i className="fab fa-linkedin-in"></i></a>
                    <a className="btn btn-light btn-social" href="#"><i className="fab fa-instagram"></i></a>
                </div> */}
            </div>
            <div className="col-lg-2 col-md-4 mb-5">
                <h4 className="font-weight-semi-bold text-primary mb-4">Quick Links</h4>
                <div className="d-flex flex-column justify-content-start">
                    <a className="text-white mb-2" href="/"><i className="fa fa-angle-right mr-2"></i>Home</a>
                    <a className="text-white mb-2" href="/about"><i className="fa fa-angle-right mr-2"></i>About Us</a>
                    <a className="text-white mb-2" href="/service"><i className="fa fa-angle-right mr-2"></i>Our Services</a>
                    {/* <a className="text-white mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Our Projects</a> */}
                    <a className="text-white" href="/contact"><i className="fa fa-angle-right mr-2"></i>Contact Us</a>
                </div>
            </div>
        </div>
    </div>
    <div className="container-fluid bg-dark text-white border-top py-4 px-sm-3 px-md-5" style={{borderColor: "#3E3E4E !important"}}>
        <div className="row">
            <div className="col-lg-6 text-center text-md-left mb-3 mb-md-0">
                <p className="m-0 text-secondary">&copy;Crystal Clear Cleaners. All Rights Reserved.
                </p>
            </div>
            {/* <div className="col-lg-6 text-center text-md-right">
                <ul className="nav d-inline-flex">
                    <li className="nav-item">
                        <a className="nav-link text-white py-0" href="#">Privacy</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link text-white py-0" href="#">Terms</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link text-white py-0" href="#">FAQs</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link text-white py-0" href="#">Help</a>
                    </li>
                </ul>
            </div> */}
        </div>
    </div>
    </>
  )
}

export default Footer