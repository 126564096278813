import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faEnvelopeOpen,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./contact.css";
import { db } from "../../services/firebase";
import { addDoc, collection } from "firebase/firestore";
import { useLocation } from "react-router-dom";

const Contactinfo = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    contactNumber: "",
    address: "",
    city: "",
    postalCode: "",
    province: "",
  });
  const location = useLocation();

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    let errors = {};
    if (!formData.name) errors.name = "Name is required";
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
    }
    if (!formData.contactNumber) {
      errors.contactNumber = "Contact number is required";
    } else if (!/^\d{10}$/.test(formData.contactNumber)) {
      errors.contactNumber = "Contact number is invalid";
    }
    if (!formData.message) errors.message = "Message is required";
    if (!formData.address) errors.address = "Address is required";
    if (!formData.city) errors.city = "City is required";
    if (!formData.postalCode) errors.postalCode = "Postal Code is required";
    if (!formData.province) errors.province = "Province is required";
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setSubmitted(true);
      // Handle form submission (e.g., send to an API)

      try {
        await addDoc(collection(db, "users"), {
          name: formData.name,
          email: formData.email,
          contact: formData.contactNumber,
          message: formData.message,
          address: formData.address,
          city: formData.city,
          postalCode: formData.postalCode,
          province: formData.province,
        });
      } catch (err) {
        alert(err);
      }
      console.log("Form submitted successfully:", formData);
    }
  };

  return (
    <>
      <div className="container-fluid pb-5 pt-5 contact-info">
        <div className="row">
          <div className="col-lg-4 p-0">
            <div className="contact-info-item d-flex align-items-center justify-content-center bg-primary text-white py-4 py-lg-0">
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                className="fa-3x text-secondary mr-4"
              />
              <div>
                <h5 className="mb-2 text-secondary">Our Office</h5>
                <p className="m-0">Brampton, Canada</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 p-0">
            <div className="contact-info-item d-flex align-items-center justify-content-center bg-secondary text-white py-4 py-lg-0">
              {/* <i className="fa fa-3x fa-envelope-open text-primary mr-4"></i> */}
              <FontAwesomeIcon
                icon={faEnvelopeOpen}
                className="fa-3x text-primary mr-4"
              />
              <div className="">
                <h5 className="mb-2 text-primary">Email Us</h5>
                <p className="m-0 text-primary">crystalclearcleaners09@gmail.com</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 p-0">
            <div className="contact-info-item d-flex align-items-center justify-content-center bg-primary text-white py-4 py-lg-0">
              <i className="fa fa-3x fa-phone-alt text-secondary mr-4"></i>
              <FontAwesomeIcon
                icon={faPhoneAlt}
                className="fa-3x text-secondary mr-4"
              />
              <div className="">
                <h5 className="mb-2 text-secondary">Call Us</h5>
                <p className="m-0">+16475072809</p>
              </div>
            </div>
          </div>
        </div>
        {location.pathname === "/contact" && (
          <div className="row mt-5" style={{ justifyContent: "center" }}>
            <div className="col-lg-4 col-12">
              <h2 className="mb-4">Contact Us</h2>
              {submitted ? (
                <div className="success-message">
                  Thank you for contacting us!
                </div>
              ) : (
                <form onSubmit={handleSubmit} noValidate>
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={formData.name}
                      onChange={handleChange}
                      className={errors.name ? "error" : ""}
                    />
                    {errors.name && (
                      <span className="error-message">{errors.name}</span>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={formData.email}
                      onChange={handleChange}
                      className={errors.email ? "error" : ""}
                    />
                    {errors.email && (
                      <span className="error-message">{errors.email}</span>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="contact">Contact</label>
                    <input
                      type="text"
                      name="contactNumber"
                      id="contactNumber"
                      value={formData.contactNumber}
                      onChange={handleChange}
                      className={errors.contactNumber ? "error" : ""}
                    />
                    {errors.contactNumber && (
                      <span className="error-message">
                        {errors.contactNumber}
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="address">Address</label>
                    <input
                      type="text"
                      name="address"
                      id="address"
                      value={formData.address}
                      onChange={handleChange}
                      className={errors.address ? "error" : ""}
                    />
                    {errors.address && (
                      <span className="error-message">{errors.address}</span>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="name">City</label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      value={formData.city}
                      onChange={handleChange}
                      className={errors.city ? "error" : ""}
                    />
                    {errors.city && (
                      <span className="error-message">{errors.city}</span>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="postalcode">Postal Code</label>
                    <input
                      type="text"
                      name="postalCode"
                      id="postalCode"
                      value={formData.postalCode}
                      onChange={handleChange}
                      className={errors.postalCode ? "error" : ""}
                    />
                    {errors.postalCode && (
                      <span className="error-message">{errors.postalCode}</span>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="province">Province</label>
                    <select
                      name="province"
                      id="province"
                      value={formData.province}
                      onChange={handleChange}
                      className={errors.province ? "error" : ""}
                    >
                      <option value="">Select Province</option>
                      <option value="ON">Ontario</option>
                    </select>
                    {errors.province && (
                      <span className="error-message">{errors.province}</span>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                      name="message"
                      id="message"
                      value={formData.message}
                      onChange={handleChange}
                      className={errors.message ? "error" : ""}
                    ></textarea>
                    {errors.message && (
                      <span className="error-message">{errors.message}</span>
                    )}
                  </div>

                  <button type="submit">Submit</button>
                </form>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Contactinfo;
