import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faBank,
  faSchool,
  faDumbbell,
  faIndustry,
  faHospital,
  faCar,
  faShop,
} from "@fortawesome/free-solid-svg-icons"; // import the specific icons you need
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const iconMap = {
  "Education / Schools": faSchool,
  "Gym and Fitness": faDumbbell,
  "Offices and Buildings": faBuilding,
  "Banks / Financial": faBank,
  "Industrial / Manufacturing": faIndustry,
  Retail: faShop,
  "Car Dealership": faCar,
  "Medical Facilities": faHospital,
  // Add more mappings as needed
};

const ServiceCard = ({ service }) => {
  const showAlert = (service) => {
    MySwal.fire({
      title: "Services Included",
      html: (
        <ul className="service-points">
          {service.points.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      ),
      // icon: "info",
      confirmButtonText: "OK",
      confirmButtonColor: "green"
    });
  };

  return (
    <div className="service-card" onClick={() => showAlert(service)}>
      <FontAwesomeIcon icon={iconMap[service.type]} className="service-icon" />
      <h3 className="service-type">{service.type}</h3>
      {/* <ul className="service-points">
        {service.points.map((point, index) => (
          <li key={index}>{point}</li>
        ))}
      </ul> */}
    </div>
  );
};

export default ServiceCard;
