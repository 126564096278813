import React from "react";

const Aboutus = () => {
  return (
    <div className="container-fluid py-5 mb-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="d-flex flex-column align-items-center justify-content-center bg-about rounded h-100 py-5 px-3">
              {/* <i className="fa fa-5x fa-award text-primary mb-4"></i> */}
              {/* <FontAwesomeIcon
                icon={faAward}
                className="fa-3x text-secondary mr-4"
              /> */}
              {/* <h1 className="display-2 text-white mb-2" data-toggle="counter-up">
                25
              </h1>
              <h2 className="text-white m-0">Years Experience</h2> */}
            </div>
          </div>
          <div className="col-lg-7 pt-5 pb-lg-5">
            <h6 className="text-secondary font-weight-semi-bold text-uppercase mb-3">
              Learn About Us
            </h6>
            <h1 className="mb-4 section-title">
              We Provide The Best Cleaning Services
            </h1>
            {/* <h5 className="text-muted font-weight-normal mb-3">
              Eos kasd eos dolor vero vero, lorem stet diam rebum. Ipsum amet
              sed vero dolor sea lorem justo est dolor eos
            </h5> */}
            <p>
              Welcome to Crystal Clear Cleaners, one of Ontario's premier cleaning companies.
              From windows and blinds to cars and kitchens, carpets to
              driveways, and factory floors to office spaces – Crystal Clear Cleaners covers
              it all. At Crystal clear cleaning services, we believe cleaning goes
              beyond mere scrubbing, dusting, and polishing. It’s about
              fostering a lifestyle of comfort and making your living space
              truly feel like home. That’s why we offer a 100% Satisfaction
              Guarantee. Crystal Clear Cleaners Group and our dedicated team are committed to
              bringing light, cleanliness, coziness, and luck to your home,
              office, or industrial space. We start with a thorough site
              inspection and provide a detailed proposal outlining the cleaning
              schedule and costs. Our uniformed staff are highly skilled and
              trained in the latest techniques, materials, and cutting-edge
              equipment. We take pride in our passion for delivering the best
              results for you.<br /><br /> Have any questions? Call us today to speak with
              one of our friendly customer service representatives.
            </p>
            {/* <div className="d-flex align-items-center pt-4">
              <a href="" className="btn btn-primary mr-5">
                Learn More
              </a>
              <button
                type="button"
                className="btn-play"
                data-toggle="modal"
                data-src="https://www.youtube.com/embed/DWRcNpR6Kdc"
                data-target="#videoModal"
              >
                <span></span>
              </button>
              <h5 className="font-weight-normal text-white m-0 ml-4 d-none d-sm-block">
                Play Video
              </h5>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
