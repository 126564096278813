import { Link } from "react-router-dom";

const Header = () => {

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-1 d-none d-lg-block">
          <a href="/" className="navbar-brand w-100 h-100 m-0 p-0 ">
            {/* <h5 className="m-0  text-primary">Crystal Clear Cleaners</h5> */}
            <img
              className="img-fluid"
              src="/img/logo-transparent-png.png"
              alt=""
              style={{ marginTop: 10 }}
            />
          </a>
        </div>
        <div className="col-lg-11">
          {/* <div className="row bg-dark d-none d-lg-flex">
            
            <div className="col-lg-5 text-right">
              <div className="d-inline-flex align-items-center pr-2">
                <a className="text-primary p-2" href="">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a className="text-primary p-2" href="">
                  <i className="fab fa-twitter"></i>
                </a>
                <a className="text-primary p-2" href="">
                  <i className="fab fa-linkedin-in"></i>
                </a>
                <a className="text-primary p-2" href="">
                  <i className="fab fa-instagram"></i>
                </a>
                <a className="text-primary p-2" href="">
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
            </div>
          </div> */}
          {/* <nav className="navbar navbar-expand-lg bg-white navbar-light p-0">
            <a href="" className="navbar-brand d-block d-lg-none">
              <h1 className="m-0 display-4 text-primary">Crystal Clear Cleaners</h1>
            </a>
            <button
              type="button"
              className="navbar-toggler"
              data-toggle="collapse"
              data-target="#navbarCollapse"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-between"
              id="navbarCollapse"
            >
              <div className="navbar-nav mr-auto py-0">

                <NavLink to="/" active={location.pathname === "/"}>
                  Home
                </NavLink>
                <NavLink to="/about" active={location.pathname === "/about"}>
                  About
                </NavLink>
                <NavLink
                  to="/service"
                  active={location.pathname === "/service"}
                >
                  Service
                </NavLink>
                <NavLink
                  to="/contact"
                  active={location.pathname === "/contact"}
                >
                  Contact
                </NavLink>
              </div>
              <a href="/contact" className="btn btn-primary mr-3 d-none d-lg-block">
                Get A Quote
              </a>
            </div>
          </nav> */}

          <nav className="navbar navbar-expand-lg bg-white navbar-light p-0">
            <div className="container-fluid">
              <a href="/" className="navbar-brand d-block d-lg-none">
                <h1 className="m-0 display-4 text-primary">
                  Crystal Clear Cleaners
                </h1>
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav mr-auto mt-2">
                  <li className="nav-item">
                    <NavLink exact to="/" className="nav-link">
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/about" className="nav-link">
                      About
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/service" className="nav-link">
                      Service
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/contact" className="nav-link">
                      Contact
                    </NavLink>
                  </li>
                </ul>
                <a
                  href="/contact"
                  className="btn btn-secondary d-none d-lg-block"
                >
                  Get A Quote
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

const NavLink = ({ to, active, children }) => {
  return (
    <Link to={to} className={`nav-item nav-link ${active ? "active" : ""}`}>
      {children}
    </Link>
  );
};

export default Header;
