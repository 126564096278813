import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const Carouselindex = () => {
  return (
    <>
      <div className="container-fluid p-0 mt-2">
        <div className="carousel-inner">
          <Carousel showArrows={false} showStatus={false}>
            <div>
              <img
                className="img-fluid"
                src="/img/carousel-1.jpg"
                alt="Not found"
              />
              <div className="carousel-caption d-flex align-items-center justify-content-center">
                <div
                  className="p-5"
                  style={{ width: "100%", maxWidth: "900px" }}
                >
                  <h5 className="text-secondary text-uppercase mb-md-3">
                    Cleaning Services
                  </h5>
                  <h1 className="display-3 text-white mb-md-4">
                    Best Quality Solution In Cleaning
                  </h1>
                  {/* <a href="" className="btn btn-primary">
                    Get A Quote
                  </a> */}
                </div>
              </div>
            </div>
            <div>
              <img
                className="img-fluid"
                src="/img/carousel-2.jpg"
                alt="Not found"
              />
              <div className="carousel-caption d-flex align-items-center justify-content-center">
                <div
                  className="p-5"
                  style={{ width: "100%", maxWidth: "900px" }}
                >
                  <h5 className="text-primary text-uppercase mb-md-3">
                    Cleaning Services
                  </h5>
                  <h1 className="display-3 text-white mb-md-4">
                    Highly Professional Cleaning Services
                  </h1>
                  {/* <a href="" className="btn btn-primary">
                    Get A Quote
                  </a> */}
                </div>
              </div>
            </div>
            <div>
              <img
                className="img-fluid"
                src="/img/carousel-3.jpg"
                alt="Not found"
              />
              <div className="carousel-caption d-flex align-items-center justify-content-center">
                <div
                  className="p-5"
                  style={{ width: "100%", maxWidth: "900px" }}
                >
                  <h5 className="text-primary text-uppercase mb-md-3">
                    Cleaning Services
                  </h5>
                  <h1 className="display-3 text-white mb-md-4">
                    Experienced & Expert Cleaners
                  </h1>
                  {/* <a href="" className="btn btn-primary">
                    Get A Quote
                  </a> */}
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default Carouselindex;
