import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
const Testimonials = () => {
  return (
    <div className="container-fluid bg-testimonial py-5">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-7 pt-lg-5 pb-5">
            <h6 className="text-secondary font-weight-semi-bold text-uppercase mb-3">
              Testimonial
            </h6>
            <h1 className="section-title text-white mb-5">
              What Our Clients Say
            </h1>
            <div className="owl-carousel testimonial-carousel position-relative">
              <Carousel showArrows={false} showStatus={false}>
                <div
                  className="d-flex flex-column text-white mb-3"
                  style={{ maxHeight: "300px" }}
                >
                  <div className="d-flex align-items-center mb-3">
                    {/* <img className="img-fluid" src="/img/testimonial-1.jpg" alt="" style={{borderRadius: "50%", maxWidth: "20%"}}/> */}
                    <div className="ml-3">
                      <h5 className="text-primary">Jignesh Patel</h5>
                      <i>Pal Physio Rehab</i>
                    </div>
                  </div>
                  <p>
                    Choosing team Crystal clear for our clinic’s cleaning needs was one
                    of the best decisions we've made. The level of cleanliness
                    is outstanding, and it significantly contributes to the
                    comfort and safety of our patients. The team is efficient,
                    courteous, and always respectful of our schedule and space.
                    We have seen a noticeable improvement in the overall hygiene
                    of our clinic since we started using their services. Thank
                    you, Crystal Clear Cleaners!
                  </p>
                </div>
                <div className="d-flex flex-column text-white">
                  <div className="d-flex align-items-center mb-3">
                    {/* <img
                      className="img-fluid"
                      src="/img/testimonial-2.jpg"
                      alt=""
                      style={{ borderRadius: "50%", maxWidth: "20%" }}
                    /> */}
                    <div className="ml-3">
                      <h5 className="text-primary">Jignesh Patel</h5>
                      <i>Pal Physio Rehab</i>
                    </div>
                  </div>
                  <p>
                    Choosing team Crystal clear for our clinic’s cleaning needs was one
                    of the best decisions we've made. The level of cleanliness
                    is outstanding, and it significantly contributes to the
                    comfort and safety of our patients. The team is efficient,
                    courteous, and always respectful of our schedule and space.
                    We have seen a noticeable improvement in the overall hygiene
                    of our clinic since we started using their services. Thank
                    you, Crystal Clear Cleaners!
                  </p>
                </div>
                <div className="d-flex flex-column text-white">
                  <div className="d-flex align-items-center mb-3">
                    {/* <img
                      className="img-fluid"
                      src="/img/testimonial-3.jpg"
                      alt=""
                      style={{ borderRadius: "50%", maxWidth: "20%" }}
                    /> */}
                    <div className="ml-3">
                      <h5 className="text-primary">Jignesh Patel</h5>
                      <i>Pal Physio Rehab</i>
                    </div>
                  </div>
                  <p>
                    Choosing team Crystal clear for our clinic’s cleaning needs was one
                    of the best decisions we've made. The level of cleanliness
                    is outstanding, and it significantly contributes to the
                    comfort and safety of our patients. The team is efficient,
                    courteous, and always respectful of our schedule and space.
                    We have seen a noticeable improvement in the overall hygiene
                    of our clinic since we started using their services. Thank
                    you, Crystal Clear Cleaners!
                  </p>
                </div>
              </Carousel>
            </div>
          </div>
          {/* <div className="col-lg-5" style={{minHeight: "400px"}}>
                <div className="position-relative h-90 rounded overflow-hidden">
                    <img className="position-absolute w-100 h-90" src="img/testimonial.jpg" style={{objectFit: "cover"}} alt="hdhd" />
                </div>
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
